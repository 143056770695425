import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

const firebaseConfig = {
    apiKey: "AIzaSyBJUUGAbA0EqPpvyiD8xNo2fXfsgE3G1Hc",
    authDomain: "cec-notification.firebaseapp.com",
    projectId: "cec-notification",
    storageBucket: "cec-notification.appspot.com",
    messagingSenderId: "515534934341",
    appId: "1:515534934341:web:85cbd40f9acbd7be6b6e2c",
    measurementId: "G-RTQ2X6S7KB"
};


firebase.initializeApp(firebaseConfig)
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

export default messaging