import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    isActiveSidebar: false,
    announTab: 0,
    receivedNoti: 0,
    receivedNotiPayload: {},
  },
  mutations: {
    changeStatusSidebar(state, value) {
      // alert(state);
      state.isActiveSidebar = value
      // console.log(state);
    },
    setNotiPayload(state, newPayload) {
      state.receivedNotiPayload = newPayload;
    },
    setReceivedNotiNum(state, newNum) {
      state.receivedNoti = newNum;
    },
  },
  actions: {
    updateNotiPayload({
      commit
    }, newPayload) {
      commit('setNotiPayload', newPayload);
    },
    updateReceivedNotiNum({
      commit
    }, newNum) {
      commit('setReceivedNotiNum', newNum);
    },
  },
  getters: {
    getNotiPayload: state => state.receivedNotiPayload,
    getReceivedNotiNum: state => state.receivedNoti,
  },
  plugins: [createPersistedState()],
})